<template>
  <div class="w-100 customer-order-wrapper">
    <b-row class="mb-5">
      <b-col md="2">
        <b-input-group>
          <date-picker
            placeholder="Từ"
            class="form-control form-control-sm"
            :config="dpConfigs.date"
            v-model="apiParams.searchFromDay"
          ></date-picker>
        </b-input-group>
      </b-col>
      <b-col md="2">
        <b-input-group>
          <date-picker
            placeholder="Đến"
            class="form-control form-control-sm"
            :config="dpConfigs.date"
            v-model="apiParams.searchToDay"
          ></date-picker>
        </b-input-group>
      </b-col>
      <b-col md="2">
        <b-form>
          <b-form-select
            v-model="apiParams.status"
            :options="ORDER_STATUS"
            size="sm"
          ></b-form-select>
        </b-form>
      </b-col>
      <b-col md="1">
        <b-button
          class="font-weight-bolder customer-order-btn"
          variant="primary"
          size="sm"
          @click="fetchOrdersByCustomerId()"
          >Lọc
        </b-button>
      </b-col>
    </b-row>
    <table class="table b-table table-bordered-list table-hover col-md-12">
      <thead>
        <tr>
          <th class="text-center header-table-color icon-width"></th>
          <th class="text-center header-table-color id-width">
            ID
          </th>
          <th class="text-center header-table-color customer-width">
            Khách hàng
          </th>
          <th class="text-center header-table-color product-width">
            Sản phẩm
          </th>
          <th class="text-center header-table-color">Giá</th>
          <th class="text-center header-table-color">
            SL
          </th>
          <th class="text-center">Tổng tiền</th>
          <th class="text-center header-table-color">
            Thanh toán
          </th>
          <th class="text-center header-table-color note-width">
            Ghi chú
          </th>
          <th class="text-center header-table-color">Trạng thái</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(order, i) in mainList.orders">
          <template v-for="(orderItem, j) in order.orderItems">
            <tr
              :key="i + j + orderItem.orderItemId"
              @mouseover="hoverRow"
              @mouseleave="outRow"
              :data="`row_${order.id}`"
            >
              <td
                v-if="j === 0 || order.show"
                :rowspan="order.orderItems.length"
                class="td-center"
              >
                <div class="mt-3">
                  <i
                    v-if="order.orderType === TYPE_ORDER.SHOP"
                    class="fas fa-store icon-color text-warning"
                    v-b-tooltip.hover.bottom
                    title="Mua tại quầy"
                  ></i>

                  <i
                    v-else-if="order.orderType === TYPE_ORDER.PREORDER"
                    class="
                              fas
                              fa-shopping-basket
                              icon-color
                              text-danger
                            "
                    v-b-tooltip.hover.bottom
                    title="Đặt trước"
                  ></i>
                  <i
                    v-else-if="order.orderType === TYPE_ORDER.SHIPPING"
                    class="fas fa-truck icon-color text-success"
                    v-b-tooltip.hover.bottom
                    title="Tự vận chuyển"
                  ></i>
                  <i
                    v-else-if="order.orderType === TYPE_ORDER.EX_SHIPPING"
                    class="fas fa-box icon-color text-primary"
                    v-b-tooltip.hover.bottom
                    title="Hãng vận chuyển (Ahamove, Nhất tín ...)"
                  ></i>
                </div>
              </td>
              <td
                v-if="j === 0 || order.show"
                :rowspan="order.orderItems.length"
                class="td-center"
              >
                <router-link
                  class="order-code text-primary font-weight-bolder mb-1"
                  :to="{
                    path: `/orders/edit-order/${order.id}`,
                  }"
                  target="_blank"
                  >{{ order.id }}
                </router-link>
                <p class="mb-2">
                  {{ formatDate(order.createdAt, 'DD/MM/YYYY HH:mm') }}
                </p>
                <p class="mb-0">
                  {{ order.createdByName }}
                </p>

                <strong v-if="order.storeName">
                  {{ order.storeName }}
                </strong>
              </td>
              <td
                v-if="j === 0 || order.show"
                :rowspan="order.orderItems.length"
                class="td-center"
              >
                <p class="order-code mb-0 font-weight-bolder">
                  {{ order.customerName }}
                </p>
                <p class="order-code mb-0">
                  {{ order.customerMobile }}
                </p>
                <div v-if="order.customerAddress">
                  <strong>ĐC:</strong>
                  {{ order.customerAddress }}
                </div>
              </td>
              <!--Start of product name-->
              <td class="align-middle">
                <p class="m-0">
                  <i
                    class="fas fa-gift ml-4 icon-color"
                    v-if="orderItem.orderItemType === ITEM_TYPE.GIFT"
                  ></i>
                  <i
                    class="fas fa-tags ml-4 icon-color"
                    v-else-if="orderItem.orderItemType === ITEM_TYPE.ATTACH"
                  ></i>
                  {{ orderItem.productName }}
                </p>
                <p
                  v-if="
                    orderItem.imeiCode && orderItem.imeiCode.trim().length > 0
                  "
                >
                  <i class="text-primary font-size-sm"
                    >IMEI: {{ orderItem.imeiCode }}</i
                  >
                </p>
                <!--Start of product combos-->
                <table>
                  <tr
                    v-show="
                      orderItem.productInCombos &&
                        orderItem.productInCombos.length > 0
                    "
                    v-for="(items, index) in orderItem.productInCombos"
                    :key="index"
                    class="product-combo-border-top"
                  >
                    <td class="border-0">
                      <i
                        v-b-tooltip.hover
                        title="Sản phẩm"
                        class="fas fa-cube icon-color"
                      ></i>
                    </td>
                    <td class="border-0">
                      {{ items.productName }}({{ items.quantity }})
                    </td>
                  </tr>
                </table>

                <!--End of product combos-->
              </td>
              <!--End of product name-->
              <td class="text-right align-middle">
                {{ convertPrice(orderItem.productPrice) }}
                <p
                  v-b-tooltip.hover.bottom
                  title="Chiết khấu sản phẩm"
                  v-if="orderItem.discountAmount"
                >
                  <i
                    class="fa fa-arrow-down icon-color text-center font-size-sm"
                  ></i
                  >:
                  <strong class="text-warning">
                    {{ convertPrice(orderItem.discountAmount) }}</strong
                  >
                </p>
              </td>
              <td class="text-center align-middle">
                {{ orderItem.quantity }}
              </td>
              <td
                v-if="j === 0 || order.show"
                :rowspan="order.orderItems.length"
                class="text-right align-middle"
              >
                <p v-show="order.customerShipFee > 0" class="border-0">
                  <i
                    v-b-tooltip.hover
                    title="Phí ship"
                    class="fas fa-shipping-fast icon-color"
                  ></i>
                  : {{ convertPrice(order.customerShipFee) }}
                </p>
                <p v-show="order.shipFee > 0" class="border-0">
                  <i
                    v-b-tooltip.hover
                    title="Phí trả hãng vận chuyển"
                    class="fas fa-hand-holding-usd icon-color"
                  ></i>
                  : {{ convertPrice(order.shipFee) }}
                </p>
                <p v-show="order.discountAmount > 0" class="border-0">
                  <i
                    v-b-tooltip.hover
                    title="Chiết khấu"
                    class="fa fa-arrow-down icon-color"
                  ></i>
                  : {{ convertPrice(order.discountAmount) }}
                  <span v-if="order.discountType === AMOUNT_TYPE.PERCENT">
                    %
                  </span>
                </p>
                <strong class="total-amount-text-color">
                  {{ convertPrice(order.totalAmount) }}</strong
                >
              </td>

              <td
                v-if="j === 0 || order.show"
                :rowspan="order.orderItems.length"
                class="text-right align-middle"
              >
                <p v-show="order.depositAmount > 0" class="border-0">
                  <i
                    v-b-tooltip.hover
                    title="Đặt cọc"
                    class="fa fa-money-check-alt icon-color"
                  ></i>
                  : {{ convertPrice(order.depositAmount) }}
                </p>
                <p v-show="order.transferAmount > 0" class="border-0">
                  <i
                    v-b-tooltip.hover
                    title="Đã chuyển khoản"
                    class="fa fa-university icon-color"
                  ></i>
                  : {{ convertPrice(order.transferAmount) }}
                </p>
                <p v-show="order.creditAmount > 0" class="border-0">
                  <i
                    v-b-tooltip.hover
                    title="Tiền quẹt thẻ"
                    class="fa fa-credit-card icon-color"
                  ></i>
                  : {{ convertPrice(order.creditAmount) }}
                </p>
                <p v-show="order.installedMoneyAmount > 0" class="border-0">
                  <i
                    v-b-tooltip.hover
                    title="Trả góp"
                    class="fa fa-cube icon-color"
                  ></i>
                  : {{ convertPrice(order.installedMoneyAmount) }}
                </p>
                <span
                  v-show="order.totalAmountCustomerPay < 0"
                  v-b-tooltip.hover
                  title="Tiền thừa"
                  class="border-0 text-success font-weight-bolder"
                  >{{ convertPrice(order.changeMoney) }}
                </span>
                <span
                  v-show="order.totalAmountCustomerPay > 0"
                  v-b-tooltip.hover
                  title="Phải thu khách"
                  class="border-0 text-warning font-weight-bolder"
                  >{{ convertPrice(order.totalAmountCustomerPay) }}
                </span>
              </td>
              <td
                v-if="j === 0 || order.show"
                :rowspan="order.orderItems.length"
                class="wrapword"
              >
                <span v-if="order.codeShip && order.codeShip.trim().length > 0">
                  <strong>Mã vận đơn:</strong> {{ order.codeShip }}
                </span>
                <p
                  v-if="order.codeShip && order.codeShip.trim().length > 0"
                  class="dashed"
                ></p>
                <p v-if="order.customerNote">
                  {{ order.customerNote }}
                </p>
                <p
                  v-if="order.customerNote && order.saleNote"
                  class="dashed"
                ></p>
                <p v-if="order.saleNote">{{ order.saleNote }}</p>
              </td>
              <td
                v-if="j === 0 || order.show"
                :rowspan="order.orderItems.length"
                class="align-middle text-center"
              >
                <span v-if="order.cancelStatus" class="d-block">
                  ({{ getStatusCancelName(order.cancelStatus) }})
                </span>
                <span
                  v-text="getStatusName(order.status)"
                  style="width: max-content;"
                  :class="getStatusClasses(order)"
                ></span>
                <div
                  v-if="
                    order.status === ORDER_STATUS.SUCCESS && order.billNumber
                  "
                  class="mt-2"
                >
                  <span
                    v-text="order.billNumber"
                    class="order-code bill-number-color"
                  ></span>
                  <div class="mt-4">
                    <p v-if="order.billState === BILL_STATE.DELETED">
                      <i
                        class="fa fa-exclamation-triangle text-danger"
                        v-b-tooltip.hover.bottom
                        title="Hoá đơn đã được xoá"
                        aria-hidden="true"
                      ></i>
                    </p>
                    <p v-if="order.billState === BILL_STATE.NO_AMOUNT">
                      <i
                        class="fa fa-exclamation-triangle  text-warning"
                        v-b-tooltip.hover.bottom
                        title="Hoá đơn không có doanh thu"
                        aria-hidden="true"
                      ></i>
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </template>
        <tr v-show="!onLoadingList">
          <td
            colspan="4"
            class="
                      align-middle
                      text-right
                      icon-color
                      font-weight-bolder
                    "
          >
            Tổng
          </td>
          <td></td>
          <td class="align-middle text-right icon-color font-weight-bolder">
            {{ sumQty }}
          </td>
          <td class="align-middle text-right icon-color font-weight-bolder">
            <p class="mb-0">
              <i
                v-b-tooltip.hover
                title="Phí vận chuyển"
                class="fa fa-truck header-table-color inherit-font-size"
              ></i>
              {{ convertPrice(calculateMoneys('shipFee')) }}
            </p>
            <p class="mb-0">
              <i
                v-b-tooltip.hover
                title="Phí ship báo khách"
                class="fas fa-user header-table-color inherit-font-size"
              ></i>
              {{ convertPrice(calculateMoneys('customerShipFee')) }}
            </p>
          </td>
          <td class="align-middle text-right icon-color font-weight-bolder">
            <p class="mb-0">
              <i
                v-b-tooltip.hover
                title="Tổng chuyển khoản"
                class="fas fa-university header-table-color inherit-font-size"
              ></i>
              {{ convertPrice(calculateMoneys('transferAmount')) }}
            </p>
            <p class="mb-0">
              <i
                v-b-tooltip.hover
                title="Tổng đặt cọc"
                class="fas fa-user header-table-color inherit-font-size"
              ></i>
              {{ convertPrice(calculateMoneys('depositAmount')) }}
            </p>
            <p class="mb-0" v-b-tooltip.hover title="Tổng thu">
              {{ convertPrice(calculateMoneys('totalAmount')) }}
            </p>
          </td>
          <td colspan="2"></td>
        </tr>
      </tbody>
    </table>
    <vcl-table
      v-if="onLoadingList"
      :speed="5"
      :animate="true"
      :columns="10"
    ></vcl-table>
    <b-row>
      <b-col lg="3" md="3" sm="12">
        <p class="mt-3 text-dark font-weight-bolder font-weight">
          Tổng số:
          {{ mainList.total }}
        </p>
      </b-col>
      <b-col lg="9" md="9" sm="12">
        <b-pagination-nav
          class="customPagination"
          v-show="maxPage"
          :link-gen="linkGen"
          v-model="currentPage"
          :number-of-pages="mainList.pages"
          use-router
          @change="fetchOrdersByCustomerId()"
          align="right"
          first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
          prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
          next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
          last-class="page-item-last btn btn-icon btn-sm my-1 "
          page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
        >
          <template v-slot:first-text>
            <span>
              <i class="ki ki-bold-double-arrow-back icon-xs"></i>
            </span>
          </template>

          <template v-slot:prev-text>
            <i class="ki ki-bold-arrow-back icon-xs"></i>
          </template>

          <template v-slot:next-text>
            <i class="ki ki-bold-arrow-next icon-xs"></i>
          </template>

          <template v-slot:last-text>
            <span class="text-info">
              <i class="ki ki-bold-double-arrow-next icon-xs"></i>
            </span>
          </template>
        </b-pagination-nav>
      </b-col>
    </b-row>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
@import 'src/assets/sass/components/_variables.bootstrap.scss';

.customer-order-wrapper {
  input::-webkit-input-placeholder {
    color: #bebec8;
  }

  .autosuggest__results ul {
    list-style: none;
    cursor: pointer;
    margin-bottom: 0;
  }

  .autosuggest__results ul li {
    padding: 8px;
  }

  .order-code:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .td-center {
    vertical-align: middle;
  }

  .icon-color {
    color: $light-75;
  }

  .header-table-color {
    color: $light-75;
  }

  .table-bordered-list th {
    border: 0.5px solid $light-25 !important;
    background-color: $light-0;
  }

  .table-bordered-list td {
    border: 0.5px solid $light-25;
  }

  .dashed {
    border: 1px dashed $gray-700;
    border-style: none none dashed;
    background-color: #fff;
  }

  input {
    border: 1px solid $light-25;
  }

  .wrapword {
    white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
    white-space: -webkit-pre-wrap; /* Chrome & Safari */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    white-space: pre-wrap; /* CSS3 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    word-break: break-word;
    white-space: normal;
  }

  .customer-order-btn {
    width: fit-content;
  }

  .icon-width {
    width: 2%;
  }

  .customer-width {
    width: 10%;
  }

  .product-width {
    width: 20%;
  }

  .id-width {
    width: 2%;
  }

  .note-width {
    width: 15%;
  }

  .inherit-font-size {
    font-size: inherit;
  }

  .bill-number-color {
    color: $warning-75;
  }

  .total-amount-text-color {
    color: $success-native;
  }

  .customPagination:deep .page-item-first .page-link {
    background-color: $gray-100;
  }

  .customPagination:deep .page-item-prev .page-link {
    background-color: $gray-100;
  }

  .customPagination:deep .page-item-next .page-link {
    background-color: $gray-100;
  }

  .customPagination:deep .page-item-last .page-link {
    background-color: $gray-100;
  }

  .customPagination:deep .page-item .page-link {
    border-radius: 0.42rem;
    font-size: 0.9rem;
    line-height: 1rem;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    -webkit-box-align: center;
    height: 2.25rem;
    min-width: 2.25rem;
    padding: 0.5rem;
    color: $dark-50;
  }

  .customPagination:deep .page-item {
    color: $gray-100;
  }

  .customPagination:deep .page-item .router-link-exact-active {
    color: $white;
  }

  .customPagination:deep .page-item .page-link:hover {
    background-color: $primary;
    color: $white;
    transition: color 0.15s ease, background-color 0.15s ease,
      border-color 0.15s ease, box-shadow 0.15s ease,
      -webkit-box-shadow 0.15s ease;
    opacity: 1;
  }

  .status-width {
    width: max-content;
  }

  .product-combo-border-top {
    border-top: 1px solid $gray-500;
  }
}
</style>

<script>
import { VclTable } from 'vue-content-loading';
import ApiService from '@/core/services/api.service';
import { cmdUrl } from '@/utils/apiUrl';
import _ from 'lodash';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import { formatDate, convertPrice, makeToastFaile } from '@/utils/common';

import { ORDER_STATUS } from '@/utils/constants';
import { ORDER_STATUS as ORDER_STATUS_ENUM } from '@/utils/enum';
import ENUM, {
  AMOUNT_TYPE,
  BILL_STATE,
  ITEM_TYPE,
  TYPE_ORDER,
} from '@/utils/enum';
import moment from 'moment';

export default {
  props: ['customerId'],
  components: {
    VclTable,
  },
  data() {
    return {
      ORDER_STATUS,
      ORDER_STATUS_ENUM,
      mainList: {
        orders: [],
        total: 0,
        pages: 1,
      },
      apiParams: {
        page: 1,
        pageSize: 10,
        searchFromDay: null,
        searchToDay: null,
        status: null,
        searchStatus: null,
        customerId: null,
      },
      onLoadingList: false,
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      currentPage: 1,
      sumShipFee: 0,
      sumCustomerShipFee: 0,
      sumTransferAmount: 0,
      sumDepositAmount: 0,
      sumTotalAmount: 0,
      sumQuantity: 0,
      listCancelStatus: [
        { value: '', text: 'Chọn lý do' },
        { value: '1', text: 'Đã mua tại quầy' },
        { value: '2', text: 'Đơn trùng' },
        { value: '3', text: 'Đã mua nơi khác' },
        { value: '4', text: 'Không liên hệ được khách hàng' },
        { value: '5', text: 'Khách suy nghĩ thêm' },
        { value: '6', text: 'Hết hàng' },
        { value: '7', text: 'Lý do khác' },
      ],
    };
  },
  mounted() {
    this.fetchOrdersByCustomerId();
  },
  methods: {
    formatDate,
    convertPrice,
    hoverRow: function(evt) {
      const items = jQuery(evt.currentTarget);
      const dataId = jQuery(items[0]).attr('data');
      const rows = jQuery(`[data=${dataId}]`);
      rows.addClass('tr-rowspan-hover');
    },
    outRow: function(evt) {
      const items = jQuery(evt.currentTarget);
      const dataId = jQuery(items[0]).attr('data');
      const rows = jQuery(`[data=${dataId}]`);
      rows.removeClass('tr-rowspan-hover');
    },
    fetchOrdersByCustomerId: function(params) {
      if (!this.customerId) {
        makeToastFaile('Không tìm thấy Id khách hàng');
        return;
      }

      this.getParamFilters();
      // Init request header.
      this.onLoadingList = true;
      ApiService.query(cmdUrl.OrderUrl.gets, {
        params: params ? params : { ...this.apiParams },
      })
        .then((response) => {
          const { dataset, total, pages } = response.data.data;
          this.mainList = {
            orders: dataset,
            total,
            pages,
          };
          this.onLoadingList = false;
          dataset.map((item) => {
            // tính lại tiền thừa cho khách
            item.changeMoney =
              item.totalAmountCustomerPay < 0
                ? item.totalAmountCustomerPay * -1
                : item.totalAmountCustomerPay;
          });
        })
        .catch(() => {
          this.onLoadingList = false;
        });
    },
    getParamFilters() {
      this.currentPage = this.$route.query.page;
      if (!this.currentPage) {
        this.currentPage = 1;
      }

      this.apiParams.page = this.currentPage;
      this.apiParams.customerId = this.customerId;
      this.apiParams.searchStatus = this.apiParams.status
        ? [this.apiParams.status]
        : null;
      this.apiParams.searchFromDay = this.apiParams.searchFromDay
        ? moment(this.apiParams.searchFromDay, 'DD/MM/YYYY').format(
            'yyyy-MM-DD',
          )
        : null;
      this.apiParams.searchToDay = this.apiParams.searchToDay
        ? moment(this.apiParams.searchToDay, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : null;
    },
    isShowRow(listData, item) {
      return _.findIndex(listData, ['id', item.id]) === 0;
    },
    calculateDiscountPercent(bill) {
      const discount = bill.discountAmount || 0;
      const total = bill.totalAmount || 0;
      if (total !== 0) {
        return parseFloat((discount / total) * 100).toFixed(1);
      }
      return 0;
    },
    sumAmount(key) {
      const outItems = this.mainList.orders;
      return _.reduce(
        outItems,
        (sum, item) => {
          return sum + parseFloat(item[key]);
        },
        0,
      );
    },
    linkGen(pageNum) {
      return pageNum === 1
        ? `?id=${this.customerId}`
        : `?id=${this.customerId}&page=${pageNum}`;
    },
    getStatusName(id) {
      switch (id) {
        case 1:
          return 'Mới';
        case 2:
          return 'Đang xử lí';
        case 3:
          return 'Đã xác nhận';
        case 4:
          return 'Đã chuẩn bị hàng';
        case 5:
          return 'Đang đóng gói';
        case 6:
          return 'Thành công';
        case 7:
          return 'Khách hủy';
        case 8:
          return 'Hệ thống hủy';
        case 9:
          return 'Đã chuyển hoàn';
        default:
          return '';
      }
    },
    getStatusCancelName(id) {
      const cancelStt = this.listCancelStatus.find((x) => x.value == id);
      if (cancelStt) return cancelStt.text;
      return 'Chưa có trạng thái lý do';
    },
    getStatusClasses(order) {
      return {
        'badge badge-warning text-white':
          order.status === ORDER_STATUS_ENUM.NEW,
        'badge badge-light': order.status === ORDER_STATUS_ENUM.CONFIRMING,
        'badge badge-primary text-white':
          order.status === ORDER_STATUS_ENUM.CONFIRMED,
        'badge badge-warning': order.status === ORDER_STATUS_ENUM.DELIVERY,
        'badge badge-info': order.status === ORDER_STATUS.PACKING,
        'badge badge-success': order.status === ORDER_STATUS_ENUM.SUCCESS,
        'badge badge-dark':
          order.status === ORDER_STATUS_ENUM.UN_SUCCESS ||
          order.status === ORDER_STATUS_ENUM.SYSTEM_CANCEL,
        'badge badge-danger': order.status === ORDER_STATUS_ENUM.REFUNDED,
      };
    },
  },
  computed: {
    BILL_STATE() {
      return BILL_STATE;
    },
    ITEM_TYPE() {
      return ITEM_TYPE;
    },
    TYPE_ORDER() {
      return TYPE_ORDER;
    },
    AMOUNT_TYPE() {
      return AMOUNT_TYPE;
    },
    ENUM() {
      return ENUM;
    },
    sumQty() {
      let sum = 0;
      _.map(this.mainList.orders, (item) => {
        const tmpSum = _.sumBy(item.orderItems, 'quantity');
        sum += tmpSum;
      });
      return sum;
    },
    calculateMoneys() {
      return (attribute) => {
        let sum = 0;
        _.map(this.mainList.orders, (item) => {
          sum += item[attribute];
        });
        return sum;
      };
    },
    maxPage() {
      return this.mainList.pages >= 2;
    },
  },
};
</script>
